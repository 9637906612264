import React from 'react'
import Tiles from '../../../react4xp/components/Tiles'
import EmployeeCard from '../../../react4xp/components/EmployeeCard'
import SectionHeader from '../../../react4xp/components/SectionHeader'


function TilesPart({ heading, preHeading, subHeading, employees, cardStyle, strings }) {

  return (
    <Tiles
      sectionHeader={
        <SectionHeader
          layout='vertical'
          labelText={preHeading}
          title={heading}
          subtitle={subHeading}
          bulletColor='accent-1'
          strings={strings}
        />
      }
      layout={cardStyle === 'list' ? '1-column' : undefined}
      cards={employees && employees.map(employee => (
        <EmployeeCard
          cardStyle={cardStyle}
          name={employee.name}
          imgSrc={employee.imageSrc?.link}
          imgSrcMedium={employee.imageSrcMedium?.link}
          imgAlt={employee.imageSrc?.alt}
          imgWidth={960}
          title={employee.title}
          phone={employee.phone}
          email={employee.email}
          description={employee.shortDescription}
          linkedinUrl={employee.linkedin}
          ariaLabel={employee.ariaLabel}
        />
      ))}
    />
  )
}

export default (props) => <TilesPart {...props} />
